import React,{ useState } from 'react'
// mui
import { 
    Typography,
    Box,
    Stack,
} from "@mui/material";
// carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Gallery.css"
import ScrollableCard from './ScrollableCard'; 
import { Carousel } from "react-responsive-carousel";
// components
import Title from './Title'
import Paragraph from './Paragraph'

import ImageCarousel from './ImageCarousel'


const Gallery = () => {
    
    // const [currentIndex, setCurrentIndex] = useState();

    const constructionSites = [
        { name: 'Appaswamy Real Estates', location: 'https://appaswamy.com/' },
        { name: 'Baashyaam Crown', location: 'https://baashyaamcrown.com/' },
        { name: 'CasaGrand', location: 'https://www.casagrand.co.in/' },
        { name: 'India Builders', location: 'https://www.indiabuilders.in/' },
        { name: 'Traventure Homes', location: 'https://www.traventurehomes.com/' },
        { name: '100 + Individual Villas ' , location:'https://www.instagram.com/gm_civil_solutions' },
        // { name: 'Individual Villa @Ramapuram', location: '' },
        // { name: 'Individual Villa @Porur', location: '' },
        // { name: 'Individual Villa @Vadapalani', location: '' },
        // { name: 'Individual Villa @Iyyapanthangal', location: '' },
        // { name: 'Individual Villa @Pozhichalur', location: '' },
        // { name: 'Individual Villa @Pallavaram', location: '' },
        // { name: 'Individual Villa @Pammal', location: '' },
      ];

      const handleClick = (event, location) => {
        event.preventDefault(); // Prevent default link behavior
        window.location.href = location; // Navigate to the URL in the current window
      };
     const imageData = [
        {
            alt: 'A',
            url: 'https://images.pexels.com/photos/259751/pexels-photo-259751.jpeg?cs=srgb&dl=pexels-pixabay-259751.jpg&fm=jpg'
        },
        
        {
            alt: 'A',
            url: 'https://images.pexels.com/photos/259751/pexels-photo-259751.jpeg?cs=srgb&dl=pexels-pixabay-259751.jpg&fm=jpg'
        },
        {
            alt: 'A',
            url: 'https://images.pexels.com/photos/259751/pexels-photo-259751.jpeg?cs=srgb&dl=pexels-pixabay-259751.jpg&fm=jpg'
        },{
            alt: 'A',
            url: 'https://images.pexels.com/photos/259751/pexels-photo-259751.jpeg?cs=srgb&dl=pexels-pixabay-259751.jpg&fm=jpg'
        },
       
        
       
    ];

    // const imageData = [
    //     {
    //         alt: 'image1',
    //         url: 'https://images.pexels.com/photos/259751/pexels-photo-259751.jpeg?cs=srgb&dl=pexels-pixabay-259751.jpg&fm=jpg'
    //     },
    //     {
    //         alt: 'image2',
    //         url: 'https://images.pexels.com/photos/5411784/pexels-photo-5411784.jpeg?cs=srgb&dl=pexels-andrea-davis-5411784.jpg&fm=jpg'
    //     },
    //     {
    //         alt: "image3",
    //         url: 'https://images.pexels.com/photos/356809/pexels-photo-356809.jpeg?cs=srgb&dl=pexels-daniel-frank-356809.jpg&fm=jpg'
    //     },
    //     {
    //         alt: "image4",
    //         url: 'https://images.pexels.com/photos/6267516/pexels-photo-6267516.jpeg?cs=srgb&dl=pexels-get-lost-mike-6267516.jpg&fm=jpg'
    //     },
    //     {
    //         alt: "image5",
    //         url: 'https://images.pexels.com/photos/667838/pexels-photo-667838.jpeg?cs=srgb&dl=pexels-huseyn-kamaladdin-667838.jpg&fm=jpg'
    //     },
    // ];
  
    // const renderSlides = imageData.map((image) => (
    // <div key={image.alt}>
    //     <img src={image.url} alt={image.alt} />
    // </div>
    // ));


    // const handleChange = (index) => {
    //     setCurrentIndex(index);
    // }

    return (
        <Stack
        direction='column'
        justifyContent= 'center'
        alignItems= 'center'
        sx={{
            py: 8,
            px: 2,
            display: { xs: 'flex'},
        }}
        >
            <Box
            component='section'
            sx={{
                paddingBottom: 3,
            }}
            >
                <Title 
                text={
                    'Our Prime clients we saved from leakage'
                }
                textAlign={'center'}
                />
                <Typography
                variant='h5'
                component='h4'
                align='center'
                sx={{
                    paddingTop: 1,
                }}
                >
                   We have provided our quality services at various locations for these companies.<br />
  Click to visit the construction companie's official websites.
                </Typography>
                <Paragraph text={
                    'We have more than 100 reviews and our\
                    customers trust our quality work.\
                    If you are interested,\
                    please feel free to contact us.\
                    Follow our Instagram page to connect with us.'
                    
                } 
                maxWidth = {'sm'}
                mx={'auto'}
                textAlign={'center'}
                />
            </Box>

            <div className="list-container">
      {/* <h2 style={{textAlign:'center'}}>List of Construction Companies & Individual Villas we saved from leakages</h2> */}
      <ul className="my-list">
        {constructionSites.map((site, index) => (
          <li key={index}  onClick={(e) => handleClick(e, site.location)} className="list-item">
            <span className="company-name">{site.name}</span>
            {/* <span className="site-location">{site.location}</span> */}
          </li>
        ))}
      </ul>
    </div>
{/*             
            <Box sx={{ 
                maxWidth: 700,
                width: '100%',
            }}>
<ScrollableCard images={imageData}/> */}

{/* <ImageCarousel/> */}
        {/* //   images={imageData}
        //   currentIndex={currentIndex}
        //   setCurrentIndex={setCurrentIndex}/> */}
                {/* <Carousel
                centerSlidePercentage={40}
                thumbWidth={180}
                dynamicHeight={false}
                centerMode={false}
                showArrows={false}
                autoPlay={false}
                infiniteLoop={true}
                selectedItem={imageData[currentIndex]}
                onChange={handleChange}
                className="carousel-container"
                >
                {renderSlides}
                </Carousel> */}
            {/* </Box> */}


        </Stack>
    )
}

export default Gallery