import React from "react";
import Content from "../components/Content";

import Gallery from "../components/Gallery";

const About = () => {
  return (
    <>
      {/* for testing purpose  */}
      {/* <Content /> */}

      {/* this is for picture showing */}

      <Gallery />
    </>
  );
};

export default About;
