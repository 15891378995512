import React from 'react'
import {
    AppBar,
    Toolbar,
    Box,
    List,
    ListItem,
    Typography, 
    styled,
    ListItemButton,
    ListItemText,
} from '@mui/material';
// menu
import DrawerItem from './DrawerItem';
// rotas
import { Link } from 'react-router-dom';


import logo from "../assets/lo.png"


// personalizacao
const StyledToolbar = styled(Toolbar) ({
    display: 'flex',
    justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up("sm")] : {
        display: "flex",
    },
}));

//rotas
const itemList = [
    {
      text: "Home",
      to: "/" 
    },
    {
      text: "About",
      to: "/about"
    },
    {
        text: "Contact",
        to: "/contact"
    }
];


const Navbar = () => {

    const handleContactUs = () => {
        // Replace '1234567890' with your actual phone number
        window.location.href = 'tel:+917358584157';
    };
    
    return (
        <AppBar 
        component="nav" 
        position="sticky"
        sx={{ 
            backgroundColor: 'red', 
        }}
        elevation={0}
        >
            <StyledToolbar>

            <img src={logo} alt="Logo" style={{ height: 40, width: 'auto' }} />
                
                <Typography
                variant="h6"
                component="h2"

                >
                      &nbsp;&nbsp;&nbsp;  GM CIVIL SOLUTIONS
                </Typography>
                <Box sx={{display: { xs: 'block', sm: 'none' } }}>
                    <DrawerItem /> 
                </Box>
                {/* ///////////////////// */}
               
                <ListItemButton
                            onClick={handleContactUs}
                            sx={{

                               
                                color: '#fff',
                                "&:hover": {
                                    backgroundColor: 'transparent',
                                   
                                    color: '#1e2a5a',
                                }
                            }}
                        >
                             <Box
            sx={{
                
                "&:hover": {
                    backgroundColor: 'black',
                   color:'yellow',
                    "& $boxContainer": {
                        color: 'red', 
                    }
                },
                border: '1px solid #0a0101',
                borderRadius: '15px',
                backgroundColor:'black',
                padding: '8px',
                width: '39px', 
                height:'38px',
                transition: 'color 0.3s ease',
                
            }}
        >
                            <ListItemText primary="Call"        primaryTypographyProps={{
                        variant: 'h6', // Choose appropriate variant (e.g., h6 for bold)
                       component:'h2',
                    }} />
                            </Box>
                        </ListItemButton>
 
                            {/* ///////////////////// */}
                <ListMenu>
                    {itemList.map( ( item ) => {
                        const { text } = item;
                        return(
                            <ListItem key={text}>
                                <ListItemButton component={Link} to={item.to}
                                sx={{
                                    color: '#fff',
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                        color: '#1e2a5a',
                                    }
                                }}
                                >
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </ListMenu>
            </StyledToolbar>
        </AppBar>
    )
}

export default Navbar;
